<template>
  <div class="flex gap-24 mt-40">
    <div class="flex-1">
      <st-box>
        <!-- 3.1-5-1 (1) -->
        <st-form-title :formTitle="$t('studio.business_bank_setting.account.question1')" required />
        <div class="relative">
          <dropdown
            rules="required"
            name="account.bankCountry"
            :options="countryOptions"
            :placeholder="$t('studio.business_bank_setting.individual.question2')"
            :dropdownProps="{
              size: 'lg',
              variant: 'line',
              distance: 0,
              offset: [0, 0],
              placement: 'bottom-start',
              class: 'w-full'
            }"
            :defaultOptionForEmptySearchResult="defaultCountryOption"
            searchable
            closeOnClick
            :disabled="isDisabled"
            useTranslate
            useTooltip
          />
        </div>
        <div class="mt-[2rem]">
          <template v-if="bankCountry === DEFAULT_COUNTRY">
            <!-- 3.1-5-1 (2) -->
            <st-form-title
              :formTitle="$t('studio.business_bank_setting.account.question2')"
              required
              class="mt-32"
            />
            <div class="relative">
              <!-- 3.1-5-1 (2-1) -->
              <dropdown
                name="account.bankCode"
                :options="krBanks ?? []"
                :placeholder="$t('studio.business_bank_setting.account.question3_place_holder')"
                inputClass="flex-[8]"
                class="w-full flex mt-[1.2rem]"
                :rules="{
                  required: $t('studio.business_bank_setting.account.question3_msg1')
                }"
                :dropdownProps="{
                  size: 'lg',
                  variant: 'line',
                  distance: 0,
                  offset: [0, 0],
                  placement: 'bottom-start',
                  class: 'w-full'
                }"
                closeOnClick
                searchable
                :disabled="!isClickEdit || isDisabled || correctBankInfo"
              />
            </div>
            <div class="mt-8">
              <!-- 3.1-5-1 (2-2) -->
              <input-text
                name="account.accountNo"
                :placeholder="$t('studio.business_bank_setting.account.question4_place_holder')"
                :rules="{
                  required: $t('studio.business_bank_setting.account.question4_msg1'),
                  max_length: {
                    length: 50,
                    message: $t('studio.common.def_key.exceed', { length: 50 })
                  }
                }"
                :disabled="!isClickEdit || isDisabled || correctBankInfo"
                :pattern="ONLY_NUMBER_REGEX"
              />
            </div>
            <template v-if="!isManual">
              <div class="mt-8 flex items-center gap-16">
                <!-- 3.1-5-1 (2-3) -->
                <input-text
                  name="account.accountHolder"
                  :placeholder="$t('studio.business_bank_setting.account.question5_place_holder')"
                  :rules="{
                    required: {
                      value: true,
                      message: $t('studio.business_bank_setting.account.question5_msg1'),
                      showError: false
                    }
                  }"
                  :disabled="!isClickEdit || isDisabled || correctBankInfo"
                  :containerClass="`${
                    accountHolderError ? 'border-2 border-error focus-within:border-error' : ''
                  }`"
                />

                <!-- 3.1-5-1 (2-4) -->
                <s-button
                  variant="secondary"
                  size="sm"
                  class="shrink-0 min-w-84"
                  :hasArrow="false"
                  :isDisabled="isDisabled"
                  @click="handleCheckBankInfo"
                >
                  {{ $t(buttonName) }}
                </s-button>
              </div>
              <st-error-message v-if="accountHolderError" name="account.accountHolder" />
            </template>
            <div v-else class="mt-8">
              <input-text
                name="account.accountHolder"
                :placeholder="$t('studio.business_bank_setting.account.question5_place_holder')"
                :rules="{
                  required: $t('studio.business_bank_setting.account.question5_msg1')
                }"
                :disabled="isDisabled"
              />
            </div>
            <p
              v-if="accountHolder && incorrectBankInfo !== ''"
              class="flex items-start gap-[.2rem] mt-[.3rem] text-xs leading-xs font-regular"
              :class="{
                'text-error': incorrectBankInfo === Confirmation.YES,
                'text-information': incorrectBankInfo === Confirmation.NO
              }"
            >
              <s-icon
                icon="ic-v2-state-warning-circle-fill"
                size="xl"
                class="flex h-16 mt-[.1rem]"
              />
              <!-- 3.1-5-1 (2-9 & 2.10) -->
              <safe-html :html="$t(contentCheckIncorrectBank)" />
            </p>
          </template>

          <template v-else>
            <!-- 3.1-6-1 (2-1-1) -->
            <st-form-title
              :formTitle="$t('studio.business_bank_setting.account.question3')"
              required
              class="mt-32"
            />
            <div class="relative flex flex-col gap-8">
              <!-- 3.1-6-1 (2-1) -->
              <input-text
                name="account.bankName"
                :placeholder="$t('studio.business_bank_setting.account.question3_usa_place_holder')"
                :rules="{
                  required: $t('studio.business_bank_setting.account.question3_usa_msg1'),
                  max_length: {
                    length: 50,
                    message: $t('studio.common.def_key.exceed', { length: 50 })
                  }
                }"
                :disabled="isDisabled"
              />
              <!-- 3.1-6-1 (2-2) -->
              <input-text
                name="account.branchName"
                :placeholder="$t('studio.business_bank_setting.account.question7_usa_place_holder')"
                :rules="{
                  required: $t('studio.business_bank_setting.account.question7_usa_msg1'),
                  max_length: {
                    length: 50,
                    message: $t('studio.common.def_key.exceed', { length: 50 })
                  }
                }"
                :disabled="isDisabled"
              />
              <!-- 3.1-6-1 (2-4) -->
              <input-text
                name="account.bankAddress"
                :placeholder="$t('studio.business_bank_setting.account.question8_usa_place_holder')"
                :rules="{
                  required: $t('studio.business_bank_setting.account.question8_usa_msg1'),
                  max_length: {
                    length: 50,
                    message: $t('studio.common.def_key.exceed', { length: 50 })
                  }
                }"
                :disabled="isDisabled"
              />
            </div>

            <st-form-title
              :formTitle="$t('studio.business_bank_setting.account.question2')"
              required
              class="mt-32"
            />
            <div class="relative flex flex-col gap-8">
              <!-- 3.1-6-1 (2-5) -->
              <input-text
                name="account.swiftCode"
                :placeholder="$t('studio.business_bank_setting.account.question9_usa_place_holder')"
                :rules="{
                  required: $t('studio.business_bank_setting.account.question9_usa_msg1'),
                  max_length: {
                    length: 50,
                    message: $t('studio.common.def_key.exceed', { length: 50 })
                  }
                }"
                :disabled="isDisabled"
              />
              <!-- 3.1-6-1 (2-6) -->
              <input-text
                v-if="isEUBank"
                :name="nationInfo.name"
                :placeholder="$t(nationInfo.placeholder)"
                inputClass="flex-[8]"
                :rules="{
                  required: $t(nationInfo.requiredMsg),
                  max_length: {
                    length: 50,
                    message: $t('studio.common.def_key.exceed', { length: 50 })
                  }
                }"
                :disabled="isDisabled"
              />
              <input-text
                v-else-if="bankCountry === NationsAddress.CANADA"
                :name="nationInfo.name"
                :placeholder="$t(nationInfo.placeholder)"
                inputClass="flex-[8]"
                :rules="{
                  required: $t(nationInfo.requiredMsg),
                  max_length: {
                    length: 50,
                    message: $t('studio.common.def_key.exceed', { length: 50 })
                  }
                }"
                :disabled="isDisabled"
              />
              <input-text
                v-else
                name="account.routingNo"
                :placeholder="
                  $t('studio.business_bank_setting.account.question10_usa_place_holder')
                "
                :rules="{
                  required: $t('studio.business_bank_setting.account.question10_usa_msg1'),
                  max_length: {
                    length: 50,
                    message: $t('studio.common.def_key.exceed', { length: 50 })
                  },
                  number: true
                }"
                :disabled="isDisabled"
              />

              <!-- 3.1-6-1 (2-7) -->
              <input-text
                name="account.accountNo"
                :placeholder="$t('studio.business_bank_setting.account.question4_place_holder')"
                :rules="{
                  required: $t('studio.business_bank_setting.account.question4_msg1'),
                  max_length: {
                    length: 50,
                    message: $t('studio.common.def_key.exceed', { length: 50 })
                  }
                }"
                :disabled="isDisabled"
              />
            </div>

            <!-- 3.1-6-1 (2-1-2) -->
            <st-form-title
              :formTitle="$t('studio.business_bank_setting.account.question5')"
              required
              class="mt-32"
            />
            <div class="relative flex gap-12">
              <!-- 3.1-6-1 (2-8) -->
              <div class="w-full">
                <input-text
                  name="account.firstName"
                  :placeholder="$t('studio.business_bank_setting.account.question5_place_holder1')"
                  :rules="{
                    required: $t('studio.business_bank_setting.account.question5_msg11'),
                    max_length: {
                      length: 50,
                      message: $t('studio.common.def_key.exceed', { length: 50 })
                    }
                  }"
                  :pattern="NO_SPACES_REGEX"
                  :disabled="isDisabled"
                />
              </div>
              <!-- 3.1-6-1 (2-9) -->
              <div class="w-full">
                <input-text
                  name="account.lastName"
                  :placeholder="$t('studio.business_bank_setting.account.question5_place_holder2')"
                  :rules="{
                    required: $t('studio.business_bank_setting.account.question5_msg12'),
                    max_length: {
                      length: 50,
                      message: $t('studio.common.def_key.exceed', { length: 50 })
                    }
                  }"
                  :pattern="NO_SPACES_REGEX"
                  :disabled="isDisabled"
                />
              </div>
            </div>
          </template>
          <!-- 3.1-6-1 (3) -->
          <upload-bank-book
            name="account.bankBookName"
            :contractNo="bankBookContractNo"
            :groupId="selectedGroupId"
            :label="$t('studio.business_bank_setting.account.question6')"
            :fileDesc1="$t('studio.business_bank_setting.account.question6_guide')"
            :fileDesc2="
              $t('studio.business_bank_setting.account.upload_guide', {
                info: 'jpg,&nbsp png,&nbsp pdf &nbsp| &nbsp 20MB'
              })
            "
            :buttonText="$t('studio.business_bank_setting.individual.btn_attach')"
            :required="isRequiredUploadBankBook"
            :rules="{
              required: isRequiredUploadBankBook
                ? {
                  value: true,
                  showError: false,
                  message: $t('studio.business_bank_setting.account.question6_req_msg')
                }
                : false,
              extension: {
                extensions: BUSINESS_IMAGE_EXTENSION,
                message: $t('studio.file_upload.val_popup_file_format_not_supported'),
                showError: false,
                errorViewType: ErrorViewTypes.POPUP
              },
              max_size: {
                size: (2 * TEN_MEGABYTES) / 1024,
                message: $t('studio.file_upload.val_popup_size_exceed'),
                showError: false,
                errorViewType: ErrorViewTypes.POPUP
              },
              file_count: {
                max: 1,
                message: $t('studio.file_upload.val_popup_select_one'),
                showError: false,
                errorViewType: ErrorViewTypes.POPUP
              }
            }"
            :isDisabled="isDisabled"
            @removeFile="removeUploadedFile"
            @onUploadFile="handleUploadFile"
          />
          <st-error-message v-if="hasBankBookError" :name="'account.bankBookName'" />
        </div>
      </st-box>
      <div class="mt-40 flex justify-center gap-16">
        <s-button
          variant="primary"
          size="lg"
          class="!min-w-160"
          :isDisabled="isDisabled"
          @click="handleSubmit"
        >
          {{ $t('studio.business_bank_setting.info_review_req_btn') }}
        </s-button>
      </div>
    </div>
    <bank-setting-guide />
  </div>
</template>
<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import {
  useFieldError,
  useFieldValue,
  useIsFieldValid,
  useSetFieldValue,
  useValidateField
} from 'vee-validate';
import { computed, ref, toRefs, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { checkKrBankInfoApi } from '@/apis/business-bank-info.api';
import BankSettingGuide from '@/components/business-bank-info/common/guide/bank-setting-guide.vue';
import UploadBankBook from '@/components/business-bank-info/common/upload-file.vue';
import SafeHtml from '@/components/common/safe-html.vue';
import StBox from '@/components/common/st-box.vue';
import StFormTitle from '@/components/common/st-form-title.vue';
import Dropdown from '@/components/validation/dropdown.vue';
import InputText from '@/components/validation/input-text.vue';
import StErrorMessage from '@/components/validation/st-error-message.vue';
import { showAlert, showConfirm } from '@/composables/useDialog';
import { BUSINESS_BANK_STATUS } from '@/constants/business-bank.const';
import { MODE } from '@/constants/common.const';
import { DEFAULT_COUNTRY } from '@/constants/cookie.const';
import { COMMON_ERROR_MESSAGE_KEY, STATUS_CODE } from '@/constants/error.const';
import { BUSINESS_IMAGE_EXTENSION, TEN_MEGABYTES } from '@/constants/file.const';
import {
  DEFAULT_COUNTRY_CODE_FOR_SEARCH_COUNTRY,
  EU_NATIONAL_LIST
} from '@/constants/national-list.const';
import { NO_SPACES_REGEX, ONLY_NUMBER_REGEX } from '@/constants/regex.const';
import { Confirmation, NationsAddress } from '@/enums/common.enum';
import { ErrorViewTypes, RuleNames } from '@/enums/validation.enum';
import { findKrBankName } from '@/mappers/bank.mapper';
import { useBusinessBankStore } from '@/stores/business-bank.store';
import { useCountryStore } from '@/stores/country.store';
import { useUserStore } from '@/stores/user.store';
import type { ErrorResponse } from '@/types/common/common.type';
import type { BusinessBankContract } from '@/types/common/file.type';
import type { FormOption } from '@/types/common/form.type';

const props = withDefaults(
  defineProps<{
    mode?: string;
    isEditKoreaBankInfo?: boolean;
  }>(),
  {
    mode: MODE.CREATE,
    isEditKoreaBankInfo: false
  }
);

const emits = defineEmits<{
  onSubmit: [v: void];
}>();

const { mode } = toRefs(props);

const { t } = useI18n();

const userStore = useUserStore();
const countryStore = useCountryStore();
const { selectedGroupId } = storeToRefs(userStore);

const businessBankStore = useBusinessBankStore();
const { fetchKrBanks } = businessBankStore;
const { krBanks, bankApproval } = storeToRefs(businessBankStore);
const { countryOptions } = storeToRefs(countryStore);
const defaultCountryOption = countryOptions.value.find(
  (item: FormOption) => item.value === DEFAULT_COUNTRY_CODE_FOR_SEARCH_COUNTRY
);

let tempBankCode = '';
let tempAccountHolder = '';

const bankCountry = useFieldValue<string>('account.bankCountry');

const bankCode = useFieldValue<string>('account.bankCode');
const setBankCode = useSetFieldValue<string>('account.bankCode');
const validateBankCode = useValidateField('account.bankCode');
const isBankCodeValid = useIsFieldValid('account.bankCode');

const bankAccountNo = useFieldValue<number>('account.accountNo');
const validateAccountNo = useValidateField('account.accountNo');
const isAccountNoValid = useIsFieldValid('account.accountNo');

const accountHolder = useFieldValue<string>('account.accountHolder');
const setAccountHolder = useSetFieldValue<string>('account.accountHolder');
const validateAccountHolder = useValidateField('account.accountHolder');
const isAccountHolderValid = useIsFieldValid('account.accountHolder');

const bankBookContractNo = useFieldValue<number[]>('account.bankBookContractNo');
const setBankBookContractNo = useSetFieldValue<number[]>('account.bankBookContractNo');

const setSearchNo = useSetFieldValue<string>('searchNo');

const setBankName = useSetFieldValue<string>('account.bankName');

const bankBook = useFieldValue<BusinessBankContract[]>('account.bankBook');
const setBankBook = useSetFieldValue<BusinessBankContract[]>('account.bankBook');
const setBankBookName = useSetFieldValue<BusinessBankContract[]>('account.bankBookName');

const bankBookError = useFieldError('account.bankBookName');

const accountHolderError = useFieldError('account.accountHolder');

const isManual = ref<boolean>(false);
const isClickEdit = ref<boolean>(!props.isEditKoreaBankInfo);
const isDisabled = computed(() => {
  return bankApproval.value?.status === BUSINESS_BANK_STATUS.REQUEST;
});

const incorrectBankInfo = ref<string>('');
const correctBankInfo = ref<boolean>(false);

const isEUBank = computed(() => EU_NATIONAL_LIST.includes(bankCountry.value));
const isRequiredUploadBankBook = computed(() => bankCountry.value === DEFAULT_COUNTRY);

const hasBankBookError = computed(() => {
  return !!bankBookError.value && JSON.parse(bankBookError.value).rule === RuleNames.REQUIRED;
});

const nationInfo = computed(() => {
  let name = '';
  let label = '';
  let placeholder = '';
  let requiredMsg = '';
  let title = '';
  if (isEUBank.value) {
    title = 'IBAN CODE';
    name = 'account.ibanCode';
    label = 'studio.business_bank_setting.account.question11';
    placeholder = 'studio.business_bank_setting.account.question11_eu_place_holder';
    requiredMsg = 'studio.business_bank_setting.account.question10_eu_msg1';
  } else if (bankCountry.value === NationsAddress.CANADA) {
    title = 'CC CODE';
    name = 'account.ccCode';
    label = 'studio.business_bank_setting.account.question12';
    placeholder = 'studio.business_bank_setting.account.question12_ca_place_holder';
    requiredMsg = 'studio.business_bank_setting.account.question12_ca_msg1';
  }
  return {
    title,
    name,
    label,
    placeholder,
    requiredMsg
  };
});

const contentCheckIncorrectBank = computed(() =>
  incorrectBankInfo.value === Confirmation.NO
    ? 'studio.business_bank_setting.account.question5_btn_msg4'
    : 'studio.business_bank_setting.account.question5_btn_msg3'
);

const buttonName = computed(() => {
  if (correctBankInfo.value) {
    return 'studio.business_bank_setting.btn_modify';
  }

  if (mode.value === MODE.EDIT) {
    return isClickEdit.value
      ? 'studio.business_bank_setting.account.question5_btn'
      : 'studio.business_bank_setting.btn_modify';
  }

  return 'studio.business_bank_setting.account.question5_btn';
});

const validate = async () => {
  await validateBankCode();
  await validateAccountNo();
  await validateAccountHolder();
  return isBankCodeValid.value && isAccountNoValid.value && isAccountHolderValid.value;
};

const handleAccountInfo = async () => {
  try {
    if (!(await validate())) {
      return;
    }
    const requestParams = {
      nation: bankCountry.value,
      bankCode: bankCode.value,
      bankAccountNumber: (bankAccountNo.value || '').toString(),
      bankAccountName: accountHolder.value
    };

    const res = await checkKrBankInfoApi(selectedGroupId.value, requestParams);
    if (res) {
      incorrectBankInfo.value = Confirmation.NO;
      correctBankInfo.value = true;
      setSearchNo(res.searchNo);
      return;
    }

    incorrectBankInfo.value = Confirmation.YES;
  } catch (err) {
    correctBankInfo.value = false;
    incorrectBankInfo.value = Confirmation.YES;
    const error = err as ErrorResponse;

    if (
      error.statusCode === STATUS_CODE.ACCOUNT_NOT_FOUND ||
      error.statusCode === STATUS_CODE.MISSING_BANK_INFO
    ) {
      const result = await showConfirm({
        content: t('studio.business_bank_setting.account.question5_btn_msg2'),
        confirmLabel: t('studio.business_bank_setting.account.question5_btn_msg2_btn2'),
        cancelLabel: t('studio.business_bank_setting.account.question5_btn_msg2_btn1')
      });
      if (!result) {
        isManual.value = true;
        incorrectBankInfo.value = '';
      }

      return;
    }

    await showAlert({
      content: t(COMMON_ERROR_MESSAGE_KEY)
    });
  }
};

const handleCheckBankInfo = async () => {
  if (!isClickEdit.value) {
    isClickEdit.value = true;
    return;
  }

  if (correctBankInfo.value) {
    correctBankInfo.value = false;
    return;
  }

  await handleAccountInfo();
};

const handleCheckFetchBankInfo = async () => {
  try {
    const requestParams = {
      nation: bankCountry.value,
      bankCode: bankCode.value,
      bankAccountNumber: (bankAccountNo.value || '').toString(),
      bankAccountName: accountHolder.value
    };
    const res = await checkKrBankInfoApi(selectedGroupId.value, requestParams);

    if (res) {
      correctBankInfo.value = true;
      incorrectBankInfo.value = Confirmation.NO;
      setSearchNo(res.searchNo);
      return;
    }

    incorrectBankInfo.value = Confirmation.YES;
  } catch (error) {
    incorrectBankInfo.value = Confirmation.YES;
  }
};

const removeUploadedFile = () => {
  setBankBook([]);
  setBankBookName([]);
};

const handleUploadFile = (res: BusinessBankContract) => {
  setBankBook([res]);
};

const handleSubmit = async () => {
  if (
    bankCountry.value === DEFAULT_COUNTRY &&
    isClickEdit.value &&
    !correctBankInfo.value &&
    !isManual.value
  ) {
    await showAlert({
      severity: 'info',
      content: t('studio.business_bank_setting.account.btn_save_alert2'),
      confirmVariant: 'blue'
    });
    return;
  }

  emits('onSubmit');
};

watch(
  () => bankCode.value,
  async (newValue: string) => {
    if (newValue && krBanks.value) {
      const bankName = findKrBankName(krBanks.value, newValue);
      setBankName(bankName);

      if (bankApproval.value?.status === BUSINESS_BANK_STATUS.REQUEST) {
        await handleCheckFetchBankInfo();
      }
    }
  }
);

// watch bankCountry === DEFAULT_COUNTRY to call api fetchKrBanks
watch(bankCountry, async (country: string) => {
  if (country === DEFAULT_COUNTRY) {
    await fetchKrBanks();
    if (tempBankCode) {
      setBankCode(tempBankCode);
    }
    if (tempAccountHolder) {
      setAccountHolder(tempAccountHolder);
    }
  } else {
    tempBankCode = bankCode.value;
    tempAccountHolder = accountHolder.value;
  }
});

watch(
  bankBook,
  (newValue: BusinessBankContract[]) => {
    const bankBookContractNo = newValue.map(({ contractNo }: BusinessBankContract) => contractNo);

    setBankBookContractNo(bankBookContractNo);
  },
  {
    immediate: true
  }
);

const init = async () => {
  if (!krBanks.value && bankCountry.value === DEFAULT_COUNTRY) {
    await fetchKrBanks();
  }
};

init();
</script>
