<template>
  <st-title
    :navItems="[
      {
        name: $t('studio.business_bank_setting.title'),
        url: 'business-bank'
      },
      {
        name: $t('studio.business_bank_setting.title2')
      }
    ]"
    :title="$t('studio.business_bank_setting.title2')"
  />
  <bank-register
    v-if="!isLoading"
    :mode="mode"
    :isEditKoreaBankInfo="isEditKoreaBankInfo"
    @onSubmit="onSubmit"
  />
</template>
<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useForm } from 'vee-validate';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

import { definePageMeta } from '@/.nuxt/imports';
import { createUpdateBankInfoApi } from '@/apis/business-bank-info.api';
import BankRegister from '@/components/business-bank-info/bank-info/register.vue';
import StTitle from '@/components/common/st-title.vue';
import { useApp } from '@/composables/useApp';
import { showAlert, showConfirm } from '@/composables/useDialog';
import { MODE } from '@/constants/common.const';
import { DEFAULT_COUNTRY } from '@/constants/cookie.const';
import { COMMON_ERROR_MESSAGE_KEY, STATUS_CODE } from '@/constants/error.const';
import { BUSINESS_BANK_PAGE_URL } from '@/constants/url.const';
import { createBankInfoMapper } from '@/mappers/bank.mapper';
import { useAppStore } from '@/stores/app.store';
import { useBusinessBankStore } from '@/stores/business-bank.store';
import { useUserStore } from '@/stores/user.store';
import type { BankInfoModel } from '@/types/bank-info/bank-model.type';
import type { ErrorResponse } from '@/types/common/common.type';
import { redirectTo } from '@/utils/common.util';

definePageMeta({
  middleware: 'check-bank-register-middleware'
});

const { t } = useI18n();
const app = useApp();

const userStore = useUserStore();
const { selectedGroupId } = storeToRefs(userStore);

const router = useRouter();

const appStore = useAppStore();

const { gdsInfo } = storeToRefs(appStore);

const businessBankStore = useBusinessBankStore();
const { fetchBankInfos } = businessBankStore;
const { bankInfo, bankStatus } = storeToRefs(businessBankStore);

const isLoading = ref(true);
const mode = ref(MODE.CREATE);
const isEditKoreaBankInfo = ref(false);

const isEdit = computed(() => bankStatus.value !== '');
const isDisabled = computed(() => router.currentRoute.value.query.mode === MODE.VIEW);

const { handleSubmit, setValues } = useForm<BankInfoModel>({
  initialValues: {
    account: {
      bankCountry: gdsInfo.value?.nation,
      bankName: '',
      accountNo: undefined,
      accountHolder: '',
      bankBook: [],
      bankBookName: '',
      bankAddress: '',
      swiftCode: '',
      routingNo: '',
      branchName: '',
      firstName: '',
      lastName: '',
      ibanCode: '',
      ccCode: ''
    },
    searchNo: ''
  }
});

const handleCreateBank = async (groupId: string, params: BankInfoModel) => {
  const result = await showConfirm({
    content: t('studio.business_bank_setting.individual.btn_save_alert1'),
    confirmLabel: t('studio.common.popup_case_cf_btn'),
    cancelLabel: t('studio.common.popup_case_cancel_btn'),
    isCloseButtonShown: true
  });

  if (result) {
    try {
      const requestParams = createBankInfoMapper(params);
      const res = await createUpdateBankInfoApi(groupId, requestParams, isEdit.value);

      if (res) {
        await showAlert({
          content: t('studio.business_bank_setting.individual.btn_save_alert2'),
          severity: 'info',
          confirmLabel: t('studio.common.popup_case_cf_btn'),
          confirmClasses: '!w-full !max-w-full',
          isCloseButtonShown: true
        });
        await fetchBankInfos();
        redirectTo(BUSINESS_BANK_PAGE_URL);
      }
    } catch (err) {
      const error = err as ErrorResponse;
      const errorCode = error.statusCode ?? 0;
      if (errorCode === STATUS_CODE.BANK_ALREADY_REQUEST) {
        const res = await showAlert({
          content: t('studio.business_bank_setting.account.btn_save_alert3'),
          severity: 'info',
          confirmLabel: t('studio.common.popup_case_cf_btn'),
          confirmClasses: '!w-full !max-w-full'
        });

        if (res) {
          redirectTo(BUSINESS_BANK_PAGE_URL);
        }

        return;
      }
      if (errorCode === STATUS_CODE.GROUP_MIGRATION_ALREADY_PROGRESS || errorCode === STATUS_CODE.PROJECT_MIGRATION_ALREADY_PROGRESS) {
        return;
      }
      showAlert({
        content: t(COMMON_ERROR_MESSAGE_KEY),
        severity: 'info',
        confirmLabel: t('studio.common.popup_case_cf_btn'),
        confirmClasses: '!w-full !max-w-full'
      });
    }
  }
};

const onSubmit = handleSubmit(async (value: BankInfoModel) => {
  if (!(await app.checkMenuWritable())) {
    return;
  }
  await handleCreateBank(selectedGroupId.value, value);
});

const fetchBankRegistration = async () => {
  if (bankInfo.value) {
    mode.value = MODE.EDIT;
    setValues({ ...bankInfo.value });

    if (bankInfo.value.account.bankCountry === DEFAULT_COUNTRY) {
      isEditKoreaBankInfo.value = true;
    }
  }
};

const init = async () => {
  if (bankStatus.value !== '' || isDisabled.value) {
    await fetchBankRegistration();
  }

  isLoading.value = false;
};

init();
</script>
