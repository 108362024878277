<template>
  <right-wing>
    <right-wing-item
      v-for="(guide, idx) in showGuides"
      :key="guide.title + idx"
      :rightWingTitle="guide.title"
      :numberValue="`0${idx + 1}`"
      :class="{
        'mt-24': idx > 0
      }"
    >
      <safe-html :html="guide.content" />
    </right-wing-item>
  </right-wing>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

import RightWing from '@/components/app/right-wing/index.vue';
import RightWingItem from '@/components/app/right-wing/item.vue';
import safeHtml from '@/components/common/safe-html.vue';

const { t } = useI18n();

const showGuides = [
  {
    title: t('studio.business_bank_setting.account.guide1'),
    content: t('studio.business_bank_setting.account.guide1_1')
  },
  {
    title: t('studio.business_bank_setting.account.guide2'),
    content: t('studio.business_bank_setting.account.guide2_1')
  }
];
</script>
